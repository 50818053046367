import React from 'react';
import {SectionTextWrapper} from "./about.styles";

const AboutSection = ({children}) => {
    return (
        <SectionTextWrapper>
            {children}
        </SectionTextWrapper>
    );
};

export default AboutSection;
