import React from 'react';
import {AboutRemarkContainer} from "./about-remark.styles";

const AboutRemark = () => {
    return (
        <AboutRemarkContainer>
            <h1>Reach out to us and let's make something great together.</h1>
        </AboutRemarkContainer>
    );
};

export default AboutRemark;
