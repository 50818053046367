import styled from "styled-components";
import {typeScale} from "../../utils/elements";

export const SectionTextWrapper = styled.section`
  width: 60%;
  margin: 20px auto;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  @media screen and (max-width: 767px) {
    width: 90%;
    height: 100%;
    margin: 100px auto;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: 80%;
    height: 100%;
    margin: 100px auto 100px auto;
  }
  
  h5 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: ${typeScale.header4};
    font-weight: normal;
    line-height: 2.5rem;
    
    @media screen and (max-width: 767px) {
        font-size: ${typeScale.paragraph};
        line-height: 1.8rem;
      }
  }
`;