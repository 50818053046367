import styled from "styled-components";
import {typeScale} from "../../utils/elements";

export const AboutRemarkContainer = styled.section`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  
  @media screen and (max-width: 767px) {
    width: 80%;
    height: 40vh;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: 60%;
    height: 30vh;
  }
  
  h1 {
    font-size: ${typeScale.header2};
    text-align: center;
    
    @media screen and (max-width: 767px) {
      font-size: ${typeScale.header4};
    }
  }
`;