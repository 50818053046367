import styled from "styled-components";
import Img from "gatsby-image";

export const AboutGridContainer = styled.section`
  height: auto;
  width: 80%;
  margin: 20px auto 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  border-top: 1px solid rgba(0,0,0,0.04);
  
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: 100%;
    grid-gap: 50px;
    padding-top: 30px;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    padding-top: 30px;
  }
`;

export const AboutGridInnerRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  
    h4 {
        padding-left: 50px;
        margin-bottom: 20px;
        color: rgba(0,0,0,0.6);
        
        @media screen and (max-width: 767px) {
          text-align: left;
          padding-left: 25px;
          padding-right: 25px;
        }
        
          @media screen and (max-width: 1200px) {
            
          }
    }
`;

export const AboutInnerRow = styled.div`
  padding: 10px 50px 20px 50px;
  font-size: 1.2rem;
  line-height: 2rem;
 
  @media screen and (max-width: 767px) {
      padding: 10px 25px 20px 25px;
      font-size: 1.1rem;
      line-height: 1.3rem;
  }
  
  @media screen and (max-width: 1200px) {
    font-size: 1.1rem;
  }
`;

export const AboutGridImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  
  @media screen and (max-width: 767px) {
      width: 100%;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    height: 80%;
  }
`;