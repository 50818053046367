import styled from "styled-components";
import Img from "gatsby-image"
import {typeScale} from "../../utils/elements";

export const MainGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 80%;
  margin: 120px auto 20px auto;
  align-items: center;
  height: 70vh;
  
  @media screen and (max-width: 767px) {
    height: 100%;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin: 150px auto 30px auto;
  }
`;

export const AboutMainText = styled.h4`
  position: relative;
  transform: translateX(-50px);
  opacity: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: ${typeScale.header4};
  line-height: 190%;
  text-transform: uppercase;
  
  @media screen and (max-width: 767px) {
    font-size: ${typeScale.header5};
  }
`;

export const AboutMainImage = styled(Img)`
  width: 100%;
  height: 100%;
`;