import React, {useEffect, useRef} from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutMain from "../components/about-main/about-main.component";
import AboutSection from "../components/about-section-text/about-section.component";
import AboutGrid from "../components/about-grid/about-grid.component";
import {AboutGridImage, AboutGridInnerRow, AboutInnerRow} from "../components/about-grid/about-grid.styles";
import {graphql, useStaticQuery} from "gatsby";
import AboutRemark from "../components/about-remark/about-remark.component";
import {Paragraph, Title} from "../components/home-about/home-about.style";
import {paragraphAnim} from "../components/home-about/home-about.component";
import {gsap, ScrollTrigger} from "gsap/all";

export default () => {
    let workPara = useRef(null);
    // let workPara2 = useRef(null);
    let doPara = useRef(null);
    let projPara = useRef(null);
    let approachPara = useRef(null);
    let approachPara2 = useRef(null);

    useEffect(() => {
        typeof window !== `undefined` ? gsap.registerPlugin(ScrollTrigger) : console.log();
        paragraphAnim(workPara);
        // paragraphAnim(workPara2);
        paragraphAnim(doPara);
        paragraphAnim(projPara);
        paragraphAnim(approachPara);
        paragraphAnim(approachPara2);
    })

    const data = useStaticQuery(graphql`
        query {
            aboutImg1: file(relativePath: { eq: "priscilla-du-preez-XkKCui44iM0-unsplash.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            
            aboutImg2: file(relativePath: { eq: "photo-of-three-people-smiling-while-having-a-meeting-3184338.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <Layout navScroll={false}>
            <SEO title="About"/>
            <AboutMain/>
            <AboutSection>
                <Title>WHAT WE DO</Title>
                <Paragraph ref={el => doPara = el}>
                    QUBE PCS manages, commercial, civil, residential, aviation, utilities and infrastructure projects
                    both locally and internationally throughout every stage of its complexity.
                </Paragraph>
            </AboutSection>
            <AboutGrid>
                <AboutGridImage fluid={data.aboutImg1.childImageSharp.fluid}/>
                <AboutGridInnerRow>
                    <h4>OUR WORK</h4>
                    <AboutInnerRow ref={el => workPara = el}>
                        We work closely with clients, surveyors, architects, contractors and consultants
                        throughout all phases of design development and building processes to ensure projects
                        are delivered with the most effective outcomes.
                    </AboutInnerRow>
                    {/*<AboutInnerRow ref={el => workPara2 = el}>*/}
                    {/*    We also complete project assurance, built asset audits, business improvement*/}
                    {/*    including GAP Analysis; ‘Knowledge Management Systems’ (KMS) design and*/}
                    {/*    implementation.*/}
                    {/*</AboutInnerRow>*/}
                </AboutGridInnerRow>
            </AboutGrid>
            <AboutSection>
                <Title>PROJECTS</Title>
                <Paragraph ref={el => projPara = el}>
                    We pride ourselves on our “Right First Time and Can Do Attitude” approach striving
                    to provide a level of service that is tailored to every client’s needs, with the main goal of
                    delivering projects which are cost and time effective.
                    Clients find that our flexibility, proactivity, speed, versatility, empathy and our “No
                    nonsense approach” make us easy to work with.
                </Paragraph>
            </AboutSection>
            <AboutGrid>
                <AboutGridInnerRow>
                    <h4>OUR APPROACH</h4>
                    <AboutInnerRow ref={el => approachPara = el}>
                        Although the scale and scope of every project is different, some objectives remain
                        constant: completely understand the needs and values of the project and teams,
                        including the culture, client and locale with the view to delivering a first class product
                        for the best price in the least amount of time whilst providing a positive, realistic and
                        driven attitude throughout.
                    </AboutInnerRow>
                    <AboutInnerRow ref={el => approachPara2 = el}>
                        With a comprehensive portfolio of commercial, institutional and infrastructure work
                        on an international level by our chartered professionals, QUBE PCS is your
                        innovative approach to project and programme delivery.
                    </AboutInnerRow>
                </AboutGridInnerRow>
                <AboutGridImage fluid={data.aboutImg2.childImageSharp.fluid}/>
            </AboutGrid>
            <AboutRemark/>
        </Layout>
    );
};


