import React from 'react';
import {AboutGridContainer} from "./about-grid.styles";

const AboutGrid = ({children}) => {
    return (
        <AboutGridContainer>
            {children}
        </AboutGridContainer>
    );
};

export default AboutGrid;
