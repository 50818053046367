import React, {useEffect, useRef} from 'react';
import {AboutMainImage, AboutMainText, MainGridContainer} from "./about-main.styles";
import {graphql, useStaticQuery} from "gatsby";
import {Bar} from "../service-main/service-main.styles";
import Img from "gatsby-image";
import {gsap, Power3} from "gsap/all";
import {Container} from "../home-about/home-about.style";

const AboutMain = () => {
    let headerTitle = useRef(null);
    useEffect(() => {
        gsap.to(headerTitle, {duration: 1, x: 0, opacity: 1, delay: .5, ease: Power3.easeIn})
    })

    const data = useStaticQuery(graphql`
        query {
            aboutImg: file(relativePath: { eq: "team.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            decorator: file(relativePath: { eq: "decorator.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 5000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <MainGridContainer>
            <Container>
                <AboutMainText ref={el => headerTitle = el}>
                    IT’S OUR RESPONSIBILITY TO SUPPORT OUR CLIENTS TO MEET THEIR NEEDS, MAXIMISE VALUE AND OVERCOME THE
                    CHALLENGES THEY FACE, EFFICIENTLY. </AboutMainText>
                <Bar><Img fluid={data.decorator.childImageSharp.fluid}/></Bar>
            </Container>
            <AboutMainImage fluid={data.aboutImg.childImageSharp.fluid}/>
        </MainGridContainer>
    );
};

export default AboutMain;
